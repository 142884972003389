export enum BADGE_COLOR {
  RED = 'RED',
  GREEN = 'GREEN',
  BLUE = 'BLUE',
}
export type BadgeProps = {
  className?: string;
  color: BADGE_COLOR;
};
export type BadgeStyledProps = {
  color: BADGE_COLOR;
};