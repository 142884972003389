import { AppFCC } from '@types';
import { Badge } from '@kit/badge';
import { withBadgeBadgeClassName, WithBadgeStyled } from './styled';
import { WithBadgeProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const WithBadge: AppFCC<WithBadgeProps> = props => {
  const {
    children,
    text,
    color
  } = props;
  return _jsxs(WithBadgeStyled, {
    children: [children, !text ? null : _jsx(Badge //
    , {
      className: withBadgeBadgeClassName,
      color: color,
      children: text
    })]
  });
};