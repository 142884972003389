import { useMemo } from 'react';
import { formatCounter } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { calendarGetConsultationsCount } from '@selectors/calendar';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { BADGE_COLOR } from '@kit/badge';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarCalendarWidgetIconStyled } from './styled';
import { SidebarNavbarCalendarWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarCalendarWidget: AppFCC<SidebarNavbarCalendarWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const calendarConsultationsCount = useTypedSelector(calendarGetConsultationsCount);
  const calendarRouteDescriptor = useMemo(() => ROUTES.calendar.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(calendarRouteDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(calendarRouteDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile]);
  return _jsx(NavbarItem, {
    text: "\u041C\u043E\u0439 \u043A\u0430\u043B\u0435\u043D\u0434\u0430\u0440\u044C",
    badgeColor: BADGE_COLOR.GREEN,
    badgeText: formatCounter(calendarConsultationsCount),
    icon: _jsx(SidebarNavbarCalendarWidgetIconStyled, {}),
    routeDescriptor: calendarRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};