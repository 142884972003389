import { useMemo } from 'react';
import { isSpecialist } from '@helpers/member';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarSearchWidgetIconStyled } from './styled';
import { SidebarNavbarSearchWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarSearchWidget: AppFCC<SidebarNavbarSearchWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const isFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const _isSpecialist = isSpecialist(roleType);
  const searchRouteDescriptor = useMemo(() => ROUTES.search.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(searchRouteDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(searchRouteDescriptor);
  const accessControlCallbacks = useMemo(() => isFilledAndApproved ? [accessControlCallbackByFilledProfile] : [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile, isFilledAndApproved]);
  return _jsx(NavbarItem, {
    text: _isSpecialist //
    ? 'Найти клиента' : 'Найти психолога',
    icon: _jsx(SidebarNavbarSearchWidgetIconStyled, {}),
    routeDescriptor: searchRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};