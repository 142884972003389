import { View, ViewId, ViewsList } from '@types';
import { appCreateSelector, RootState } from '@store';
import { ViewsById, ViewsIds, ViewsState } from '@slices/views';

/**
 * regular selectors
 */

export const viewsGet = (state: RootState): ViewsState => {
  return state.app.views;
};

/**
 * memoized reselect selectors
 */

export const viewsGetIds: (state: RootState) => ViewsIds = appCreateSelector([viewsGet], views => views.viewsIds);
export const viewsGetListById: (state: RootState) => ViewsById = appCreateSelector([viewsGet],
//
views => views.viewsById);
export const viewsGetById: (state: RootState, id: ViewId) => View = appCreateSelector([viewsGetListById, (state_, id: ViewId) => id], (viewsById, id) => viewsById[id]);
const viewsGetListHasMore: (state: RootState) => boolean = appCreateSelector([viewsGet],
//
views => views.hasMore);
export const viewsGetListLimit: (state: RootState) => number = appCreateSelector([viewsGet],
//
views => views.limit);
export const viewsGetListOffset: (state: RootState) => number = appCreateSelector([viewsGet],
//
views => views.offset);
export const viewsGetListTotalCount: (state: RootState) => number = appCreateSelector([viewsGet],
//
views => views.totalCount);
export const viewsGetListDataLoaded: (state: RootState) => boolean = appCreateSelector([viewsGet],
//
views => views.dataLoaded);
export const viewsGetList: (state: RootState) => ViewsList = appCreateSelector([viewsGetIds, viewsGetListHasMore, viewsGetListLimit, viewsGetListOffset, viewsGetListTotalCount, viewsGetListDataLoaded, (state: RootState) => state], (viewsIds, hasMore, limit, offset, totalCount, dataLoaded, state) => {
  return {
    items: viewsIds.map(id => viewsGetById(state, id)),
    hasMore,
    limit,
    offset,
    totalCount,
    dataLoaded
  };
});
export const viewsGetPendingCount: (state: RootState) => number = appCreateSelector([viewsGet],
//
views => views.pendingViewsCount);