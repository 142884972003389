import { BUTTON_BASE_COLORED_COLOR, ButtonBaseColoredOnClick, ButtonBaseColoredProps, ButtonBaseColoredRefElement } from '../button-base-colored';
export const BUTTON_COLOR = BUTTON_BASE_COLORED_COLOR;
export enum BUTTON_SIZE {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE',
}
export type ButtonRefElement = ButtonBaseColoredRefElement;
export type ButtonOnClick = ButtonBaseColoredOnClick;
export type ButtonProps = ButtonBaseColoredProps & {
  size: BUTTON_SIZE;
  onClick?: ButtonOnClick;
};
export type ButtonStyledProps = {
  size: undefined | BUTTON_SIZE;
};