import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarAccountSettingsWidgetIconStyled } from './styled';
import { SidebarNavbarAccountSettingsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarAccountSettingsWidget: AppFCC<SidebarNavbarAccountSettingsWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const accountSettingsRouteDescriptor = useMemo(() => ROUTES.account.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(accountSettingsRouteDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation], [accessControlCallbackByEmailConfirmation]);
  return _jsx(NavbarItem, {
    text: "\u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430",
    icon: _jsx(SidebarNavbarAccountSettingsWidgetIconStyled, {}),
    routeDescriptor: accountSettingsRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};