import { addMinutes } from '@helpers/date';
import { CALENDAR_TIME_SLOT_STATE, CalendarWorkingTimes, CalendarWorkingTimeTimeSlots } from '@types';
import { calendarToWorkingTimesWithoutRepeaters } from './calendar-to-working-times-without-repeaters';
export const calendarWorkingTimesToTimeSlots = (workingTimes: CalendarWorkingTimes, slotDurationMinutes: number, dateFrom?: string, dateTo?: string): CalendarWorkingTimeTimeSlots => {
  const workingTimesWithoutRepeaters = calendarToWorkingTimesWithoutRepeaters({
    workingTimes,
    dateFrom,
    dateTo
  });
  const _dateFrom = !dateFrom //
  ? undefined : new Date(dateFrom);
  const _dateTo = !dateTo //
  ? undefined : new Date(dateTo);
  return workingTimesWithoutRepeaters.reduce((acc, curr) => {
    const temp = ({} as CalendarWorkingTimeTimeSlots);
    const originBeginTimeDate = new Date(curr.timing.beginTime);
    const originEndTimeDate = new Date(curr.timing.endTime);
    let cursor = originBeginTimeDate;
    while (cursor < originEndTimeDate) {
      const endTimeDate = addMinutes(cursor, slotDurationMinutes);
      if (_dateFrom && cursor < _dateFrom ||
      //
      _dateTo && cursor > _dateTo) {
        cursor = endTimeDate;
        continue;
      }
      const beginTimeIso = cursor.toISOString();
      const endTimeIso = endTimeDate.toISOString();
      temp[beginTimeIso] = {
        id: beginTimeIso,
        timing: {
          beginTime: beginTimeIso,
          endTime: endTimeIso
        },
        state: CALENDAR_TIME_SLOT_STATE.AVAILABLE,
        meta: {
          workingTime: {
            id: curr.id
          }
        }
      };
      cursor = endTimeDate;
    }
    return {
      ...acc,
      ...temp
    };
  }, ({} as CalendarWorkingTimeTimeSlots));
};