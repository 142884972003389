import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { QUERY_PARAM } from '@config/routing';
import { isDesktop } from '@helpers/geometry';
import { isSpecialist } from '@helpers/member';
import { ROUTES } from '@routing/routes';
import { AppFC } from '@types';
import { accountGetAccountId } from '@selectors/account';
import { currentClientGetHasStubAvatar } from '@selectors/current-client';
import { currentMemberGetAge, currentMemberGetDefaultPhotoOrAvatarUrl } from '@selectors/current-member';
import { currentMemberGetName, currentMemberGetRoleType } from '@selectors/current-member';
import { geometryGetType } from '@selectors/geometry';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { SidebarUserAchievementsWidget } from '@widgets/sidebar/sidebar-user-achievements';
import { AvatarWithNameAge, AvatarWithNameAgeAvatarOnClick } from '@kit/avatar/avatar-with-name-age';
import { SidebarUserWidgetAvatarStyled, SidebarUserWidgetInfoStyled, SidebarUserWidgetNameAgeStyled, SidebarUserWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarUserWidget: AppFC = () => {
  const {
    removePopup
  } = usePopup();
  const currentMemberId = useTypedSelector(accountGetAccountId);
  const currentMemberRoleType = useTypedSelector(currentMemberGetRoleType);
  const _isSpecialist = isSpecialist(currentMemberRoleType);
  const name = useTypedSelector(currentMemberGetName);
  const age = useTypedSelector(currentMemberGetAge);
  const defaultPhotoOrAvatarUrl = useTypedSelector(currentMemberGetDefaultPhotoOrAvatarUrl);
  const hasStubAvatar = useTypedSelector(currentClientGetHasStubAvatar);
  const geometryType = useTypedSelector(geometryGetType);
  const _isDesktop = isDesktop(geometryType);
  const routeDescriptor = useMemo(() => {
    if (!currentMemberId) {
      return ROUTES.profile.index.getDescriptor();
    }
    return ROUTES.profile.id.getDescriptor({
      [QUERY_PARAM.USER_ID]: currentMemberId
    });
  }, [currentMemberId]);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(routeDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByFilledProfile], [accessControlCallbackByFilledProfile]);
  const click: AvatarWithNameAgeAvatarOnClick = useCallback(() => {
    removePopup({
      id: POPUP.SIDEBAR
    });
  }, [removePopup]);
  return _jsxs(SidebarUserWidgetStyled, {
    isSpecialist: _isSpecialist,
    children: [_jsx(SidebarUserWidgetAvatarStyled, {
      routeDescriptor: routeDescriptor,
      accessControlCallbacks: accessControlCallbacks,
      isSpecialist: _isSpecialist,
      children: _jsx(AvatarWithNameAge, {
        url: defaultPhotoOrAvatarUrl,
        isStubAvatar: hasStubAvatar,
        name: name,
        age: age,
        showNameAge: _isSpecialist,
        avatarOnClick: click
      })
    }), _jsxs(SidebarUserWidgetInfoStyled, {
      isSpecialist: _isSpecialist,
      children: [_isSpecialist && _isDesktop ? null : _jsx(SidebarUserWidgetNameAgeStyled //
      , {
        name: name,
        age: age,
        isSpecialist: _isSpecialist
      }), !_isSpecialist ? null : _jsx(SidebarUserAchievementsWidget, {})]
    })]
  });
};