import { calculateNameAge } from '@helpers/member';
import { AppFCC } from '@types';
import { AvatarWithNameAgeAvatarStyled, AvatarWithNameAgeNameAgeStyled, AvatarWithNameAgeStyled } from './styled';
import { AvatarWithNameAgeProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const AvatarWithNameAge: AppFCC<AvatarWithNameAgeProps> = props => {
  const {
    url,
    isStubAvatar,
    name,
    age,
    showNameAge,
    avatarOnClick
  } = props;
  return _jsxs(AvatarWithNameAgeStyled, {
    children: [_jsx(AvatarWithNameAgeAvatarStyled //
    , {
      src: url,
      isStubAvatar: isStubAvatar,
      alt: calculateNameAge(name, age),
      onClick: avatarOnClick
    }), !showNameAge ? null : _jsx(AvatarWithNameAgeNameAgeStyled //
    , {
      name: name,
      age: age
    })]
  });
};