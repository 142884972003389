import { AppFC, AppFCC, STATISTICS_PERIOD_TYPE } from '@types';
import { currentSpecialistGetStatistics } from '@selectors/current-specialist';
import { useTypedSelector } from '@hooks/store';
import { SidebarStatsWidgetCoinsStyled, SidebarStatsWidgetColStyled, SidebarStatsWidgetStyled, SidebarStatsWidgetTitleStyled } from './styled';
import { _SidebarStatsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarStatsWidget: AppFC = () => {
  const today = useTypedSelector(state => currentSpecialistGetStatistics(state, STATISTICS_PERIOD_TYPE.TODAY));
  const currentMonth = useTypedSelector(state => currentSpecialistGetStatistics(state, STATISTICS_PERIOD_TYPE.CURRENT_MONTH));
  return _jsx(_SidebarStatsWidget //
  , {
    dayCoins: today?.amount,
    monthCoins: currentMonth?.amount
  });
};
export const _SidebarStatsWidget: AppFCC<_SidebarStatsWidgetProps> = props => {
  const {
    dayCoins,
    monthCoins
  } = props;
  return _jsxs(SidebarStatsWidgetStyled, {
    children: [_jsxs(SidebarStatsWidgetColStyled, {
      children: [dayCoins == null ? null : _jsx(SidebarStatsWidgetTitleStyled, {
        children: "\u0417\u0430 \u0441\u0435\u0433\u043E\u0434\u043D\u044F:"
      }), monthCoins == null ? null : _jsx(SidebarStatsWidgetTitleStyled, {
        children: "\u0417\u0430 \u043C\u0435\u0441\u044F\u0446:"
      })]
    }), _jsxs(SidebarStatsWidgetColStyled, {
      children: [dayCoins == null ? null : _jsxs(SidebarStatsWidgetCoinsStyled, {
        children: [dayCoins, " \u043C\u043E\u043D\u0435\u0442"]
      }), monthCoins == null ? null : _jsxs(SidebarStatsWidgetCoinsStyled, {
        children: [monthCoins, " \u043C\u043E\u043D\u0435\u0442"]
      })]
    })]
  });
};