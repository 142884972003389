import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { NAVIGATION_MODE, QUERY_PARAM } from '@config/routing';
import { AuthLabels } from '@localization/translations';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { accountGetIsAuthorized } from '@selectors/account';
import { usePopup } from '@hooks/popup';
import { useNavigator } from '@hooks/routing';
import { useTypedSelector } from '@hooks/store';
import { TextBold } from '@kit/text/text-bold';
import { SidebarAuthLinkWidgetIconStyled, SidebarAuthLinkWidgetStyled, SidebarAuthLinkWidgetTextStyled } from './styled';
import { SidebarAuthLinkWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarAuthLinkWidget: AppFCC<SidebarAuthLinkWidgetProps> = props => {
  const {
    className
  } = props;
  const {
    removePopup
  } = usePopup();
  const navigator = useNavigator();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const redirect = useCallback(async () => {
    if (isAuthorized) {
      await navigator.navigate(ROUTES.account.logoff.getDescriptor(undefined,
      //
      {
        mode: NAVIGATION_MODE.REPLACE
      }));
    } else {
      await navigator.navigateQuery({
        [QUERY_PARAM.SOURCE]: POPUP.ACCOUNT_LOGIN_MODAL
      }, {
        mode: NAVIGATION_MODE.REPLACE
      });
    }
    removePopup({
      id: POPUP.SIDEBAR
    });
  }, [isAuthorized, navigator, removePopup]);
  const text = useMemo(() => {
    return isAuthorized //
    ? AuthLabels.SIGN_OUT : AuthLabels.SIGN_IN;
  }, [isAuthorized]);
  return _jsxs(SidebarAuthLinkWidgetStyled //
  , {
    className: className,
    onClick: redirect,
    children: [_jsx(SidebarAuthLinkWidgetIconStyled, {}), _jsx(SidebarAuthLinkWidgetTextStyled, {
      children: _jsx(TextBold, {
        boldOnHover: true,
        children: text
      })
    })]
  });
};