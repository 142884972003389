import { AppFCC } from '@types';
import { TextBoldStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type TextBoldProps = {
  children: string;
  isHovered?: boolean;
  boldOnHover?: boolean;
};
export const TextBold: AppFCC<TextBoldProps> = props => {
  const {
    children,
    isHovered,
    boldOnHover
  } = props;
  return _jsx(TextBoldStyled //
  , {
    "data-text": children,
    isHovered: isHovered,
    boldOnHover: boldOnHover,
    children: children
  });
};