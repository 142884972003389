import { useCallback, useState } from 'react';
import { POPUP } from '@config/popups';
import { getTimezoneId, getTimezoneOffset } from '@helpers/date';
import { computeAuthVisitData } from '@helpers/logging/analytics/visit-event';
import { ROUTES } from '@routing/routes';
import { AppFC, CULTURE, ROLE_TYPE } from '@types';
import { AccountFetchExistsOnSuccess, AccountFetchExtendedRegisterOnSuccess, accountFetchExtendedRegisterWithErrorHandling } from '@actions/account/account-fetch-extended-register-with-error-handling';
import { geometryGetType } from '@selectors/geometry';
import { usePending } from '@hooks/pending';
import { usePopup } from '@hooks/popup';
import { usePush } from '@hooks/push';
import { useNavigator } from '@hooks/routing';
import { useStorage } from '@hooks/storage';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
import { SidebarRegisterForm, SidebarRegisterFormOnSubmit } from '@forms/sidebar/sidebar-register';
import { RolePickerWidget } from '@widgets/role-picker';
import { SidebarLoginWithSocialNetworkWidget } from '@widgets/sidebar/sidebar-login-with-social-network';
import { SidebarRegisterWidgetForm, sidebarRegisterWidgetRolePickerButtonClassName, SidebarRegisterWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarRegisterWidget: AppFC = () => {
  const dispatch = useTypedDispatch();
  const {
    removePopup
  } = usePopup();
  const push = usePush();
  const storage = useStorage();
  const navigator = useNavigator();
  const geometryType = useTypedSelector(geometryGetType);
  const [roleType, setRoleType] = useState(ROLE_TYPE.CLIENT);
  const [pending, setPending] = usePending(false);
  const successExists: AccountFetchExistsOnSuccess = useCallback(() => {
    removePopup({
      id: POPUP.SIDEBAR
    });
  }, [removePopup]);
  const successRegister: AccountFetchExtendedRegisterOnSuccess = useCallback(async () => {
    await navigator.navigate(ROUTES.profile.edit.index.getDescriptor());
  }, [navigator]);
  const submit: SidebarRegisterFormOnSubmit = useCallback(async formData => {
    setPending(true);
    try {
      await dispatch(accountFetchExtendedRegisterWithErrorHandling({
        accountFetchExistsPayload: {
          userName: formData.email,
          password: formData.password,
          passwordRepeated: formData.password,
          culture: CULTURE.RU,
          roleType,
          timezoneId: getTimezoneId(),
          timezoneOffset: getTimezoneOffset(),
          ...computeAuthVisitData({
            storage,
            navigator,
            geometryType
          })
        },
        accountFetchExistsOnSuccess: successExists,
        accountFetchExtendedRegisterOnSuccess: successRegister,
        storage,
        push
      }));
    } finally {
      setPending(false);
    }
  }, [setPending, dispatch, roleType, storage, navigator, geometryType, successExists, successRegister, push]);
  return _jsxs(SidebarRegisterWidgetStyled, {
    children: [_jsx(RolePickerWidget, {
      buttonClassName: sidebarRegisterWidgetRolePickerButtonClassName,
      disabled: pending,
      roleType: roleType,
      onChange: setRoleType
    }), _jsx(SidebarRegisterWidgetForm, {
      children: _jsx(SidebarRegisterForm //
      , {
        disabled: pending,
        onSubmit: submit
      })
    }), _jsx(SidebarLoginWithSocialNetworkWidget, {
      roleType: roleType
    })]
  });
};