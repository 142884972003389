import { useMemo } from 'react';
import { formatCounter } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { viewsGetPendingCount } from '@selectors/views';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarViewsWidgetIconStyled } from './styled';
import { SidebarNavbarViewsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarViewsWidget: AppFCC<SidebarNavbarViewsWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const viewsPendingCount = useTypedSelector(viewsGetPendingCount);
  const viewsRouteDescriptor = useMemo(() => ROUTES.views.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(viewsRouteDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(viewsRouteDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile]);
  return _jsx(NavbarItem, {
    text: "\u041F\u0440\u043E\u0441\u043C\u043E\u0442\u0440\u044B",
    icon: _jsx(SidebarNavbarViewsWidgetIconStyled, {}),
    badgeText: formatCounter(viewsPendingCount),
    routeDescriptor: viewsRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};