import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { isDesktop } from '@helpers/geometry';
import { AppFCC } from '@types';
import { geometryGetType } from '@selectors/geometry';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { Navbar } from '@kit/navbar';
import { SidebarNavbarAccountSettingsWidget } from './sidebar-navbar-account-settings';
import { SidebarNavbarCalendarWidget } from './sidebar-navbar-calendar';
import { SidebarNavbarInvitationsWidget } from './sidebar-navbar-invitations';
import { SidebarNavbarMessagesWidget } from './sidebar-navbar-messages';
import { SidebarNavbarProfileEditWidget } from './sidebar-navbar-profile-edit';
import { SidebarNavbarSearchWidget } from './sidebar-navbar-search';
import { SidebarNavbarViewsWidget } from './sidebar-navbar-views';
import { SidebarNavbarWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarNavbarWidget: AppFCC<SidebarNavbarWidgetProps> = props => {
  const {
    shadowPosition
  } = props;
  const {
    removePopup
  } = usePopup();
  const geometryType = useTypedSelector(geometryGetType);
  const _isDesktop = isDesktop(geometryType);
  const click = useCallback(() => {
    removePopup({
      id: POPUP.SIDEBAR
    });
  }, [removePopup]);
  return _jsxs(Navbar, {
    children: [_jsx(SidebarNavbarMessagesWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _jsx(SidebarNavbarCalendarWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _jsx(SidebarNavbarInvitationsWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _isDesktop ? null : _jsx(SidebarNavbarSearchWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _jsx(SidebarNavbarViewsWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _jsx(SidebarNavbarProfileEditWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    }), _jsx(SidebarNavbarAccountSettingsWidget, {
      shadowPosition: shadowPosition,
      onClick: click
    })]
  });
};