import { calendarIsCurrentSpecialistCalendar, calendarParticipantIsSpecialist } from '@helpers/calendar';
import { first, merge, pick } from '@helpers/data';
import { photoOrAvatarUrl, toUserAchievements } from '@helpers/member';
import { calendarConsultationsToTimeSlots } from '@mapping/calendar/calendar-consultations-to-time-slots';
import { calendarWorkingTimesToTimeSlots } from '@mapping/calendar/calendar-working-times-to-time-slots';
import { CALENDAR_PRICE_TYPE, CalendarConsultationAction, CalendarConsultationActionParticipant, CalendarConsultationId, CalendarConsultationTimeSlots, CalendarCurrentMember, CalendarCurrentMemberCalendar, CalendarCurrentMemberConsultation, CalendarCurrentMemberConsultations, CalendarCurrentMemberInfo, CalendarCurrentMemberTimeSlots, CalendarCurrentMemberWorkingTimes, CalendarDefautls, CalendarMember, CalendarMemberCalendar, CalendarMemberConsultations, CalendarMembersById, CalendarMemberTimeSlots, CalendarPrice, CalendarPrices, CalendarSpecialistInfo, CalendarTimeSlotId, CalendarWorkingTimes, CalendarWorkingTimeTimeSlots, MemberId, ROLE_TYPE, USER_ACHIEVEMENT } from '@types';
import { appCreateSelector, RootState } from '@store';
import { CalendarState } from '@slices/calendar';
import { accountGetAccountId } from '@selectors/account';

/**
 * regular selectors
 **/

export const calendarGet = (state: RootState): CalendarState => {
  return state.app.calendar;
};

/**
 * memoized reselect selectors
 **/

export const calendarGetDefautls: (state: RootState) => undefined | CalendarDefautls = appCreateSelector([calendarGet],
//
calendar => calendar.defaults);
export const calendarGetDefaultsSlotDuration: (state: RootState) => undefined | number = appCreateSelector([calendarGetDefautls],
//
defaults => defaults?.slot.durationMinutes);
export const calendarGetDefaultsConsultationMaxDuration: (state: RootState) => undefined | number = appCreateSelector([calendarGetDefautls],
//
defaults => defaults?.consultation.maxDurationMinutes);
export const calendarGetDefautlsPrices: (state: RootState) => undefined | CalendarPrices = appCreateSelector([calendarGetDefautls],
//
defaults => defaults?.prices);
export const calendarGetDefautlsCallPrice: (state: RootState) => undefined | CalendarPrice = appCreateSelector([calendarGetDefautlsPrices],
//
prices => {
  return prices //
  ?.find(price => price.itemType === CALENDAR_PRICE_TYPE.CALL);
});
export const calendarGetDefautlsCallPriceCoinsAmount: (state: RootState) => undefined | number = appCreateSelector([calendarGetDefautlsCallPrice],
//
price => price?.coinAmount);
export const calendarGetCallPrice: (state: RootState, durationMinutes: number) => undefined | number = appCreateSelector([calendarGetDefautlsCallPriceCoinsAmount, (_state, durationMinutes) => durationMinutes],
//
(coinsAmount, durationMinutes) => {
  if (!coinsAmount) {
    return undefined;
  }
  return durationMinutes * coinsAmount;
});
export const calendarGetCurrentMember: (state: RootState) => undefined | CalendarCurrentMember = appCreateSelector([calendarGet],
//
calendar => calendar.currentMember);
export const calendarGetCurrentMemberCalendar: (state: RootState) => undefined | CalendarCurrentMemberCalendar = appCreateSelector([calendarGetCurrentMember],
//
currentMember => currentMember?.calendar);
export const calendarGetCurrentMemberInfo: (state: RootState) => undefined | CalendarCurrentMemberInfo = appCreateSelector([calendarGetCurrentMember],
//
currentMember => currentMember?.info);
export const calendarGetCurrentMemberInfoStartTime: (state: RootState) => undefined | string = appCreateSelector([calendarGetCurrentMemberInfo],
//
info => info?.startTime);
export const calendarGetCurrentMemberWorkingTimes: (state: RootState) => undefined | CalendarCurrentMemberWorkingTimes = appCreateSelector([calendarGetCurrentMemberCalendar],
//
calendar => {
  if (!calendar || !calendarIsCurrentSpecialistCalendar(calendar)) {
    return undefined;
  }
  return calendar.workingTimes;
});
export const calendarGetCurrentMemberConsultations: (state: RootState) => undefined | CalendarCurrentMemberConsultations = appCreateSelector([calendarGetCurrentMemberCalendar],
//
calendar => calendar?.consultations);
export const calendarGetCurrentMemberConsultationById: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | CalendarCurrentMemberConsultation = appCreateSelector([calendarGetCurrentMemberConsultations,
//
(_state, consultationId) => consultationId], (consultations, consultationId) => consultations?.find(consultation => consultation.id === consultationId));
export const calendarGetCurrentMemberConsultationAction: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | CalendarConsultationAction = appCreateSelector([calendarGetCurrentMemberConsultationById], consultation => first(consultation?.actions));
export const calendarGetCurrentMemberConsultationParticipant: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | CalendarConsultationActionParticipant = appCreateSelector([calendarGetCurrentMemberConsultationAction, accountGetAccountId, (state: RootState) => state], (action, currentMemberId) => first(action?.participants.filter(participant => participant.userId !== currentMemberId)));
export const calendarGetCurrentMemberConsultationParticipantPhotoOrAvatarUrl: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | string = appCreateSelector([calendarGetCurrentMemberConsultationParticipant], participant => photoOrAvatarUrl(participant?.photoUrl, participant?.avatarId, undefined));
export const calendarGetCurrentMemberConsultationParticipantName: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | string = appCreateSelector([calendarGetCurrentMemberConsultationParticipant], participant => participant?.name);
export const calendarGetCurrentMemberConsultationParticipantAchievements: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | USER_ACHIEVEMENT[] = appCreateSelector([calendarGetCurrentMemberConsultationParticipant], participant => {
  if (calendarParticipantIsSpecialist(participant)) {
    return toUserAchievements(participant.isCertified, participant.profSpeciality);
  }
  return undefined;
});
export const calendarGetCurrentMemberConsultationParticipantId: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | string = appCreateSelector([calendarGetCurrentMemberConsultationParticipant], participant => participant?.userId);
export const calendarGetCurrentMemberConsultationParticipantRoleType: (state: RootState, consultationId: undefined | CalendarConsultationId) => undefined | ROLE_TYPE = appCreateSelector([calendarGetCurrentMemberConsultationParticipant], participant => participant?.roleType);
export const calendarGetCurrentMemberWorkingTimesTimeSlots: (state: RootState, dateFrom?: string, dateTo?: string) => undefined | CalendarWorkingTimeTimeSlots = appCreateSelector([calendarGetCurrentMemberWorkingTimes, calendarGetDefaultsSlotDuration, (_state, dateFrom: undefined | string) => dateFrom, (_state, _dateFrom, dateTo: undefined | string) => dateTo], (workingTimes, durationMinutes, dateFrom, dateTo) => {
  if (!workingTimes || !durationMinutes) {
    return undefined;
  }
  return calendarWorkingTimesToTimeSlots(workingTimes, durationMinutes, dateFrom, dateTo);
});
export const calendarGetCurrentMemberConsultationsTimeSlots: (state: RootState, dateFrom?: string, dateTo?: string) => undefined | CalendarConsultationTimeSlots = appCreateSelector([calendarGetCurrentMemberConsultations, calendarGetDefaultsSlotDuration, (_state, dateFrom: undefined | string) => dateFrom, (_state, _dateFrom, dateTo: undefined | string) => dateTo], (consultations, durationMinutes, dateFrom, dateTo) => {
  if (!consultations || !durationMinutes) {
    return undefined;
  }
  return calendarConsultationsToTimeSlots(consultations, durationMinutes, dateFrom, dateTo);
});
export const calendarGetCurrentMemberTimeSlots: (state: RootState, dateFrom?: string, dateTo?: string) => undefined | CalendarCurrentMemberTimeSlots = appCreateSelector([calendarGetCurrentMemberWorkingTimesTimeSlots, calendarGetCurrentMemberConsultationsTimeSlots], (workingTimesTimeSlots, consultationsTimeSlots) => {
  return merge({},
  //
  workingTimesTimeSlots, consultationsTimeSlots);
});
export const calendarGetMembersById: (state: RootState) => undefined | CalendarMembersById = appCreateSelector([calendarGet],
//
calendar => calendar.membersById);
export const calendarGetMemberById: (state: RootState, memberId: MemberId) => undefined | CalendarMember = appCreateSelector([calendarGetMembersById, (_state, memberId: MemberId) => memberId],
//
(membersById, memberId) => membersById?.[memberId]);
export const calendarGetMemberCalendar: (state: RootState, memberId: MemberId) => undefined | CalendarMemberCalendar = appCreateSelector([calendarGetMemberById],
//
member => member?.calendar);
export const calendarGetMemberInfo: (state: RootState, memberId: MemberId) => undefined | CalendarSpecialistInfo = appCreateSelector([calendarGetMemberById],
//
member => member?.info);
export const calendarGetMemberInfoVisible: (state: RootState, memberId: MemberId) => undefined | boolean = appCreateSelector([calendarGetMemberInfo],
//
info => info?.visible);
export const calendarGetMemberInfoStartTime: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector([calendarGetMemberInfo],
//
info => info?.startTime);
export const calendarGetMemberCalendarWorkingTimes: (state: RootState, memberId: MemberId) => undefined | CalendarWorkingTimes = appCreateSelector([calendarGetMemberCalendar],
//
calendar => calendar?.workingTimes);
export const calendarGetMemberCalendarConsultations: (state: RootState, memberId: MemberId) => undefined | CalendarMemberConsultations = appCreateSelector([calendarGetMemberCalendar],
//
calendar => calendar?.consultations);
export const calendarGetMemberCalendarOwner: (state: RootState, memberId: MemberId) => undefined | {
  id: MemberId;
  roleType: ROLE_TYPE.SPECIALIST;
  photoUrl: string;
} = appCreateSelector([calendarGetMemberCalendar],
//
calendar => calendar?.owner);
export const calendarGetMemberWorkingTimesTimeSlots: (state: RootState, memberId: MemberId, dateFrom?: string, dateTo?: string) => undefined | CalendarWorkingTimeTimeSlots = appCreateSelector([calendarGetMemberCalendarWorkingTimes, calendarGetDefaultsSlotDuration, (_state, _memberId, dateFrom: undefined | string) => dateFrom, (_state, _memberId, _dateFrom, dateTo: undefined | string) => dateTo], (workingTimes, durationMinutes, dateFrom, dateTo) => {
  if (!workingTimes || !durationMinutes) {
    return undefined;
  }
  return calendarWorkingTimesToTimeSlots(workingTimes, durationMinutes, dateFrom, dateTo);
});
export const calendarGetMemberConsultationsTimeSlots: (state: RootState, memberId: MemberId, dateFrom?: string, dateTo?: string) => undefined | CalendarConsultationTimeSlots = appCreateSelector([calendarGetMemberCalendarConsultations, calendarGetDefaultsSlotDuration, (_state, _memberId, dateFrom: undefined | string) => dateFrom, (_state, _memberId, _dateFrom, dateTo: undefined | string) => dateTo], (consultations, durationMinutes, dateFrom, dateTo) => {
  if (!consultations || !durationMinutes) {
    return undefined;
  }
  return calendarConsultationsToTimeSlots(consultations, durationMinutes, dateFrom, dateTo);
});
export const calendarGetMemberTimeSlots: (state: RootState, memberId: MemberId, dateFrom?: string, dateTo?: string) => undefined | CalendarMemberTimeSlots = appCreateSelector([calendarGetMemberWorkingTimesTimeSlots, calendarGetMemberConsultationsTimeSlots], (workingTimesTimeSlots, consultationsTimeSlots) => {
  return merge({},
  //
  workingTimesTimeSlots, consultationsTimeSlots);
});
export const calendarGetMemberCalendarWorkingTimesBySlotIds: (state: RootState, memberId: MemberId, slotIds: CalendarTimeSlotId[]) => undefined | CalendarWorkingTimes = appCreateSelector([(state, memberId) => calendarGetMemberCalendarWorkingTimes(state, memberId), (state, memberId) => calendarGetMemberTimeSlots(state, memberId), (_state, _memberId, slotIds: CalendarTimeSlotId[]) => slotIds], (workingTimes, timeSlots, slotIds) => {
  if (!workingTimes || !timeSlots) {
    return undefined;
  }
  const workingTimeIds = Object.values(pick(timeSlots, ...slotIds)) //
  .map(timeSlot => timeSlot.meta.workingTime.id);
  return workingTimes //
  .filter(workingTime => workingTimeIds.includes(workingTime.id));
});
export const calendarGetMemberCalendarOwnerPhotoUrl: (state: RootState, memberId: MemberId) => undefined | string = appCreateSelector([calendarGetMemberCalendarOwner],
//
owner => owner?.photoUrl);
export const calendarGetConsultationsCount: (state: RootState) => number = appCreateSelector([calendarGet],
//
calendar => calendar.pendingConsultationsCount);