import { isSpecialist } from '@helpers/member';
import { AppFC, AppFCC } from '@types';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { useTypedSelector } from '@hooks/store';
import { SidebarAccountWidget } from '@widgets/sidebar/sidebar-account';
import { NAVBAR_ITEM_SHADOW_POSITION, SidebarNavbarWidget } from '@widgets/sidebar/sidebar-navbar';
import { SidebarRatingWidget } from '@widgets/sidebar/sidebar-rating';
import { SidebarStatsWidget } from '@widgets/sidebar/sidebar-stats';
import { SidebarUserWidget } from '@widgets/sidebar/sidebar-user';
import { SidebarAuthorizedWidgetAuthLinkStyled, SidebarAuthorizedWidgetColumnDividerStyled, SidebarAuthorizedWidgetContentStyled, SidebarAuthorizedWidgetFirstColumnStyled, SidebarAuthorizedWidgetNavbarStyled, SidebarAuthorizedWidgetRatingStyled, SidebarAuthorizedWidgetSecondColumnStyled, SidebarAuthorizedWidgetStatsStyled, SidebarAuthorizedWidgetStyled } from './styled';
import { _SidebarAuthorizedWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const _SidebarAuthorizedWidget: AppFCC<_SidebarAuthorizedWidgetProps> = props => {
  const {
    roleType
  } = props;
  const _isSpecialist = isSpecialist(roleType);
  return _jsxs(SidebarAuthorizedWidgetStyled, {
    isSpecialist: _isSpecialist,
    children: [_jsxs(SidebarAuthorizedWidgetFirstColumnStyled, {
      isSpecialist: _isSpecialist,
      children: [_jsxs(SidebarAuthorizedWidgetContentStyled, {
        isSpecialist: _isSpecialist,
        children: [_jsx(SidebarUserWidget, {}), _jsx(SidebarAccountWidget, {}), _jsx(SidebarAuthorizedWidgetNavbarStyled, {
          children: _jsx(SidebarNavbarWidget, {})
        }), _jsx(SidebarAuthorizedWidgetRatingStyled, {
          isSpecialist: _isSpecialist,
          children: _jsx(SidebarRatingWidget, {})
        }), !_isSpecialist ? null : _jsx(SidebarAuthorizedWidgetStatsStyled, {
          isSpecialist: _isSpecialist,
          children: _jsx(SidebarStatsWidget, {})
        })]
      }), _jsx(SidebarAuthorizedWidgetAuthLinkStyled, {})]
    }), _jsx(SidebarAuthorizedWidgetColumnDividerStyled, {}), _jsx(SidebarAuthorizedWidgetSecondColumnStyled, {
      children: _jsx(SidebarNavbarWidget, {
        shadowPosition: NAVBAR_ITEM_SHADOW_POSITION.RIGHT
      })
    })]
  });
};
export const SidebarAuthorizedWidget: AppFC = () => {
  const roleType = useTypedSelector(currentMemberGetRoleType);
  return _jsx(_SidebarAuthorizedWidget, {
    roleType: roleType
  });
};