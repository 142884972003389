import { AppFCC } from '@types';
import { AccessControlLink, LinkContext } from '@managers/links';
import { BADGE_COLOR } from '@kit/badge';
import { WithBadge } from '@kit/with-badge';
import { NavbarItemLinkContentStyled, NavbarItemLinkHighlightStyled, NavbarItemLinkIconStyled, NavbarItemLinkTextStyled, NavbarItemStyled } from './styled';
import { NavbarItemProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
export const NavbarItem: AppFCC<NavbarItemProps> = props => {
  const {
    routeDescriptor,
    checkActive,
    routesHighlighting,
    accessControlCallbacks,
    icon,
    text,
    badgeText,
    badgeColor,
    shadowPosition,
    onClick
  } = props;
  return _jsx(NavbarItemStyled, {
    children: _jsx(AccessControlLink, {
      routeDescriptor: routeDescriptor,
      routesHighlighting: routesHighlighting,
      accessControlCallbacks: accessControlCallbacks,
      checkActive: checkActive,
      className: "group",
      onClick: onClick,
      children: _jsx(LinkContext.Consumer, {
        children: ({
          isActive
        }) => {
          return _jsxs(_Fragment, {
            children: [_jsxs(NavbarItemLinkContentStyled, {
              children: [_jsx(WithBadge //
              , {
                text: badgeText,
                color: badgeColor ?? BADGE_COLOR.RED,
                children: _jsx(NavbarItemLinkIconStyled, {
                  children: icon
                })
              }), _jsx(NavbarItemLinkTextStyled, {
                isActive: isActive,
                children: text
              })]
            }), _jsx(NavbarItemLinkHighlightStyled //
            , {
              isActive: isActive,
              shadowPosition: shadowPosition
            })]
          });
        }
      })
    })
  });
};