import { AppFCC } from '@types';
import { UserAchievementsItemIconStyled, UserAchievementsItemStyled, UserAchievementsItemTextStyled, UserAchievementsStyled } from './styled';
import { UserAchievementsProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const UserAchievements: AppFCC<UserAchievementsProps> = props => {
  const {
    className,
    itemClassName,
    itemIconClassName,
    itemTextClassName,
    items
  } = props;
  return _jsx(UserAchievementsStyled, {
    className: className,
    children: items.map(({
      icon,
      text
    }, index) => {
      return _jsxs(UserAchievementsItemStyled //
      , {
        className: itemClassName,
        children: [_jsx(UserAchievementsItemIconStyled, {
          className: itemIconClassName,
          children: icon
        }), !text ? null : _jsx(UserAchievementsItemTextStyled, {
          className: itemTextClassName,
          children: text
        })]
      }, index);
    })
  });
};