import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { ROUTES } from '@routing/routes';
import { accountGetIsAuthorized } from '@selectors/account';
import { currentMemberGetProfileIsFilledAndApproved } from '@selectors/current-member';
import { usePopup } from '@hooks/popup';
import { useNavigator } from '@hooks/routing';
import { useTypedSelector } from '@hooks/store';
import { AccountFilledProfileRequiredModalProps } from '@popups/modals/account/account-filled-profile-required';
import { UseAccessControlCallbackByFilledProfile } from './types';
export const useAccessControlCallbackByFilledProfile: UseAccessControlCallbackByFilledProfile = () => {
  const navigator = useNavigator();
  const {
    addPopup
  } = usePopup();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const profileIsFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const hasAccess = !isAuthorized || profileIsFilledAndApproved;
  const accessControlCallback = useCallback(async () => {
    const pathname = navigator.getPathname();
    const match = navigator.findMatch(pathname);
    const profileEditPageRoute = ROUTES.profile.edit.index;
    if (match?.route !== profileEditPageRoute) {
      await navigator.navigate(profileEditPageRoute.getDescriptor());
    }
    addPopup<AccountFilledProfileRequiredModalProps>({
      id: POPUP.ACCOUNT_FILLED_PROFILE_REQUIRED
    });
  }, [addPopup, navigator]);
  return hasAccess //
  ? undefined : accessControlCallback;
};