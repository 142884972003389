import { ENDPOINTS } from '@config/endpoints';
import { jsonFetch, JsonFetchResult } from '@services/base/json-fetch';
import { AccountExtendedRegisterRequestData, AccountExtendedRegisterRequestDto, AccountExtendedRegisterResponseData, AccountExtendedRegisterResponseDto } from '@services/dto/account/account-extended-register';
import { accountExtendedRegisterRequestToDto, accountExtendedRegisterResponseFromDto } from '@mapping/account/account-extended-register';
type AccountExtendedRegister = (requestData: AccountExtendedRegisterRequestData) => JsonFetchResult<AccountExtendedRegisterResponseData>;
export const accountExtendedRegister: AccountExtendedRegister = requestData => {
  const {
    promise,
    cancel
  } = jsonFetch<AccountExtendedRegisterResponseDto, AccountExtendedRegisterRequestDto>(ENDPOINTS.account.extendedRegister(), {
    method: 'POST',
    data: accountExtendedRegisterRequestToDto(requestData)
  });
  return {
    promise: promise.then(accountExtendedRegisterResponseFromDto),
    cancel
  };
};