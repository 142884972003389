import { cx } from '@emotion/css';
import { AppFCC } from '@types';
import { avatarSquaredImageClassName, AvatarSquaredStyled } from './styled';
import { AvatarSquaredProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const AvatarSquared: AppFCC<AvatarSquaredProps> = props => {
  const {
    imageClassName,
    ...restProps
  } = props;
  return _jsx(AvatarSquaredStyled, {
    imageClassName: cx(avatarSquaredImageClassName, imageClassName),
    loaderDisabled: true,
    ...restProps
  });
};