import { useMemo } from 'react';
import { calculateAvatarUrl } from '@helpers/member';
import { AppFC, AVATAR_ID } from '@types';
import { SidebarRegisterWidget } from '../sidebar-register';
import { SidebarUnauthorizedWidgetAvatarStyled, SidebarUnauthorizedWidgetColumnDividerStyled, SidebarUnauthorizedWidgetFirstColumnStyled, SidebarUnauthorizedWidgetRegisterStyled, SidebarUnauthorizedWidgetSecondColumnStyled, SidebarUnauthorizedWidgetStyled, SidebarUnauthorizedWidgetTextStyled, SidebarUnuthorizedWidgetAuthLinkStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarUnauthorizedWidget: AppFC = () => {
  const avatarUrl = calculateAvatarUrl(AVATAR_ID.AVATAR_MALE_STUB);
  const sidebarRegisterWidget = useMemo(() => _jsx(SidebarRegisterWidget, {}), []);
  return _jsxs(SidebarUnauthorizedWidgetStyled, {
    children: [_jsxs(SidebarUnauthorizedWidgetFirstColumnStyled, {
      children: [_jsx(SidebarUnauthorizedWidgetAvatarStyled, {
        src: avatarUrl
      }), _jsx(SidebarUnauthorizedWidgetTextStyled, {
        children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C \u0430\u043A\u043A\u0430\u0443\u043D\u0442:"
      }), _jsx(SidebarUnauthorizedWidgetRegisterStyled, {
        children: sidebarRegisterWidget
      }), _jsx(SidebarUnuthorizedWidgetAuthLinkStyled, {})]
    }), _jsx(SidebarUnauthorizedWidgetColumnDividerStyled, {}), _jsx(SidebarUnauthorizedWidgetSecondColumnStyled, {
      children: sidebarRegisterWidget
    })]
  });
};