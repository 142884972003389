import { accountExists } from '@services/account/account-exists';
import { accountHandleError } from '@actions/account/account-handle-error';
import { accountRelogin } from '@actions/account/account-relogin';
import { AccountFetchExists } from './types';
export const accountFetchExists: AccountFetchExists = payload => {
  return async dispatch => {
    const {
      userName,
      password
    } = payload;
    try {
      const data = await accountExists(payload).promise;
      dispatch(accountRelogin(data));
      return data;
    } catch (error) {
      dispatch(accountHandleError({
        error,
        email: userName,
        password
      }));
      throw error;
    }
  };
};