import { useCallback } from 'react';
import { sidebarRegisterValidationRules } from '@validation/rules/sidebar';
import { AppFCC } from '@types';
import { EmailInputController } from '@managers/forms/controllers/email-input-controller';
import { PasswordInputController } from '@managers/forms/controllers/password-input-controller';
import { FormContainer } from '@managers/forms/form-container';
import { BUTTON_BASE_COLORED_COLOR } from '@kit/buttons/button-base-colored';
import { ERRORS_PANE_BG_COLOR, ErrorsPane } from '@kit/errors/errors-pane';
import { WithError } from '@kit/errors/with-error';
import { SidebarRegisterFormFieldsStyled, SidebarRegisterFormStyled, SidebarRegisterFormSubmitButtonIconStyled, SidebarRegisterFormSubmitButtonStyled, SidebarRegisterFormSubmitButtonTextStyled, sidebarRegisterFormTextInputClassName, sidebarRegisterFormTextInputInputClassName } from './styled';
import { _SidebarRegisterFormProps, SidebarRegisterFormData, SidebarRegisterFormProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const _SidebarRegisterForm: AppFCC<_SidebarRegisterFormProps> = props => {
  const {
    formData,
    getValues,
    fieldErrors,
    formErrors,
    disabled,
    onSubmit
  } = props;
  const submit = useCallback((event: React.FormEvent) => {
    event.preventDefault();
    onSubmit(getValues(), event);
  }, [getValues, onSubmit]);
  return _jsxs(SidebarRegisterFormStyled, {
    onSubmit: submit,
    children: [_jsxs(SidebarRegisterFormFieldsStyled, {
      children: [_jsx(EmailInputController, {
        className: sidebarRegisterFormTextInputClassName,
        inputClassName: sidebarRegisterFormTextInputInputClassName,
        name: "email",
        autoComplete: "username",
        placeholder: "\u0412\u0430\u0448 e-mail",
        defaultValue: formData?.email ?? '',
        disabled: disabled,
        error: fieldErrors.email?.message
      }), _jsx(PasswordInputController, {
        className: sidebarRegisterFormTextInputClassName,
        inputClassName: sidebarRegisterFormTextInputInputClassName,
        name: "password",
        autoComplete: "current-password",
        placeholder: "\u041F\u0440\u0438\u0434\u0443\u043C\u0430\u0439\u0442\u0435 \u043F\u0430\u0440\u043E\u043B\u044C",
        defaultValue: formData?.password ?? '',
        disabled: disabled,
        error: fieldErrors.password?.message
      })]
    }), _jsxs(WithError, {
      children: [_jsxs(SidebarRegisterFormSubmitButtonStyled //
      , {
        color: BUTTON_BASE_COLORED_COLOR.BLUE,
        disabled: disabled,
        children: [_jsx(SidebarRegisterFormSubmitButtonTextStyled, {
          children: "\u0421\u043E\u0437\u0434\u0430\u0442\u044C"
        }), _jsx(SidebarRegisterFormSubmitButtonIconStyled, {})]
      }), _jsx(ErrorsPane //
      , {
        errors: formErrors,
        bgColor: ERRORS_PANE_BG_COLOR.RED
      })]
    })]
  });
};
export const SidebarRegisterForm: AppFCC<SidebarRegisterFormProps> = props => {
  return _jsx(FormContainer, {
    Form: _SidebarRegisterForm,
    validationRules: sidebarRegisterValidationRules,
    formProps: props
  });
};