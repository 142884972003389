import { Invitation, InvitationId, InvitationsList } from '@types';
import { appCreateSelector, RootState } from '@store';
import { InvitationsById, InvitationsIds, InvitationsState } from '@slices/invitations';

/**
 * regular selectors
 */

export const invitationsGet = (state: RootState): InvitationsState => {
  return state.app.invitations;
};

/**
 * memoized reselect selectors
 */

export const invitationsGetIds: (state: RootState) => InvitationsIds = appCreateSelector([invitationsGet], invitations => invitations.invitationsIds);
const invitationsGetById: (state: RootState) => InvitationsById = appCreateSelector([invitationsGet], invitations => invitations.invitationsById);
export const invitationsGetInvitationById: (state: RootState, id: InvitationId) => Invitation = appCreateSelector([invitationsGetById, (_state, id: InvitationId) => id], (invitationsById, id) => invitationsById[id]);
const invitationsGetListHasMore: (state: RootState /**/) => boolean = appCreateSelector([invitationsGet], invitations => invitations.hasMore);
export const invitationsGetListLimit: (state: RootState) => number = appCreateSelector([invitationsGet], invitations => invitations.limit);
export const invitationsGetListOffset: (state: RootState) => number = appCreateSelector([invitationsGet], invitations => invitations.offset);
export const invitationsGetListTotalCount: (state: RootState) => number = appCreateSelector([invitationsGet], invitations => invitations.totalCount);
export const invitationsGetListDataLoaded: (state: RootState) => boolean = appCreateSelector([invitationsGet], invitations => invitations.dataLoaded);
export const invitationsGetList: (state: RootState) => InvitationsList = appCreateSelector([invitationsGetIds, invitationsGetListHasMore, invitationsGetListLimit, invitationsGetListOffset, invitationsGetListTotalCount, invitationsGetListDataLoaded, (state: RootState) => state], (invitationsIds,
//
hasMore, limit, offset, totalCount, dataLoaded, state) => {
  return {
    items: invitationsIds.map(id => invitationsGetInvitationById(state, id)),
    hasMore,
    limit,
    offset,
    totalCount,
    dataLoaded
  };
});
export const invitationsGetPendingInvitationsCount: (state: RootState) => number = appCreateSelector([invitationsGet], invitations => invitations.pendingInvitationsCount);