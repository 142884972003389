import { accountExtendedRegister } from '@services/account/account-extended-register';
import { accountHandleError } from '@actions/account/account-handle-error';
import { accountRelogin } from '@actions/account/account-relogin';
import { currentMemberFetchGetMe } from '@actions/current-member/current-member-fetch-get-me';
import { AccountFetchExtendedRegister } from './types';
export const accountFetchExtendedRegister: AccountFetchExtendedRegister = payload => {
  return async dispatch => {
    const {
      userName,
      password
    } = payload;
    try {
      const data = await accountExtendedRegister(payload).promise;
      dispatch(accountRelogin(data.jwt));
      await dispatch(currentMemberFetchGetMe());
      return data;
    } catch (error) {
      dispatch(accountHandleError({
        error,
        email: userName,
        password
      }));
      throw error;
    }
  };
};