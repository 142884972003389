import { addMinutes } from '@helpers/date';
import { CALENDAR_TIME_SLOT_STATE, CalendarConsultationTimeSlots, CalendarMemberConsultations } from '@types';
export const calendarConsultationsToTimeSlots = (consultations: CalendarMemberConsultations, slotDurationMinutes: number, dateFrom?: string, dateTo?: string): CalendarConsultationTimeSlots => {
  const _dateFrom = !dateFrom //
  ? undefined : new Date(dateFrom);
  const _dateTo = !dateTo //
  ? undefined : new Date(dateTo);
  return consultations.reduce((acc, curr) => {
    const temp = ({} as CalendarConsultationTimeSlots);
    const originBeginTimeDate = new Date(curr.timing.beginTime);
    const originEndTimeDate = addMinutes(originBeginTimeDate, curr.timing.durationMinutes);
    if (_dateFrom && originEndTimeDate <= _dateFrom ||
    //
    _dateTo && originBeginTimeDate >= _dateTo) {
      return acc;
    }
    let cursor = originBeginTimeDate;
    while (cursor < originEndTimeDate) {
      const endTimeDate = addMinutes(cursor, slotDurationMinutes);
      if (_dateFrom && cursor < _dateFrom ||
      //
      _dateTo && cursor > _dateTo) {
        cursor = endTimeDate;
        continue;
      }
      const beginTimeIso = cursor.toISOString();
      const endTimeIso = endTimeDate.toISOString();
      temp[beginTimeIso] = {
        id: beginTimeIso,
        timing: {
          beginTime: beginTimeIso,
          endTime: endTimeIso
        },
        state: CALENDAR_TIME_SLOT_STATE.BUSY,
        meta: {
          consultation: {
            id: curr.id
          }
        }
      };
      cursor = endTimeDate;
    }
    return {
      ...acc,
      ...temp
    };
  }, ({} as CalendarConsultationTimeSlots));
};