import { AppFCC } from '@types';
import { NameAgeAgeStyled, NameAgeNameStyled, NameAgeStyled } from './styled';
import { NameAgeProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const NameAge: AppFCC<NameAgeProps> = props => {
  const {
    className,
    name,
    age
  } = props;
  return _jsxs(NameAgeStyled, {
    className: className,
    children: [!name ? null : _jsx(NameAgeNameStyled, {
      children: name
    }), !age ? null : _jsxs(NameAgeAgeStyled, {
      children: [!name ? '' : ', ', age]
    })]
  });
};