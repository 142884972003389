import { AppFCC } from '@types';
import { NavbarItemsStyled, NavbarStyled } from './styled';
import { NavbarProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Navbar: AppFCC<NavbarProps> = props => {
  const {
    children
  } = props;
  return _jsx(NavbarStyled, {
    children: _jsx(NavbarItemsStyled, {
      children: children
    })
  });
};