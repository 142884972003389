import { useMemo } from 'react';
import { formatCounter } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { chatsGetUnreadMessagesCount } from '@selectors/chats';
import { supportGetUnreadMessagesCount } from '@selectors/support';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarMessagesWidgetIconStyled } from './styled';
import { SidebarNavbarMessagesWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarMessagesWidget: AppFCC<SidebarNavbarMessagesWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const unreadMessagesCount = useTypedSelector(state => chatsGetUnreadMessagesCount(state) + (supportGetUnreadMessagesCount(state) ?? 0));
  const chatsRouteDescriptor = useMemo(() => ROUTES.chats.index.getDescriptor(), []);
  const chatRouteDescriptor = useMemo(() => ROUTES.chats.id.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(chatsRouteDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(chatsRouteDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile]);
  return _jsx(NavbarItem, {
    text: "\u041C\u043E\u0438 \u0441\u043E\u043E\u0431\u0449\u0435\u043D\u0438\u044F",
    icon: _jsx(SidebarNavbarMessagesWidgetIconStyled, {}),
    badgeText: formatCounter(unreadMessagesCount),
    routeDescriptor: chatsRouteDescriptor,
    routesHighlighting: [chatRouteDescriptor],
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};