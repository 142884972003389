import { AccessControlLinkProps } from '@managers/links/access-control-link';
import { BADGE_COLOR } from '@kit/badge';
export enum NAVBAR_ITEM_SHADOW_POSITION {
  RIGHT = 'RIGHT',
  LEFT = 'LEFT',
}
export type NavbarItemProps = {
  icon: React.ReactElement;
  text: string;
  badgeText?: string;
  badgeColor?: BADGE_COLOR;
  shadowPosition?: NAVBAR_ITEM_SHADOW_POSITION;
} & AccessControlLinkProps;
export type NavbarItemLinkHighlightStyledProps = {
  shadowPosition: undefined | NAVBAR_ITEM_SHADOW_POSITION;
  isActive: undefined | boolean;
};
export type NavbarItemLinkTextStyledProps = {
  isActive: undefined | boolean;
};