import { AppFCC } from '@types';
import { ProgressbarBarStyled, ProgressbarStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
type ProgressbarProps = {
  value: undefined | number;
};
export const Progressbar: AppFCC<ProgressbarProps> = props => {
  const {
    value
  } = props;
  const _value = Math.min(Math.max(0, value ?? 0), 100);
  return _jsx(ProgressbarStyled, {
    children: _jsx(ProgressbarBarStyled, {
      style: {
        width: `${_value}%`
      }
    })
  });
};