import { AppFCC } from '@types';
import { ExternalAuthWidget } from '@widgets/external-auth';
import { SidebarLoginWithSocialNetworkWidgetStyled, SidebarLoginWithSocialNetworkWidgetTitleStyled } from './styled';
import { SidebarLoginWithSocialNetworkWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarLoginWithSocialNetworkWidget: AppFCC<SidebarLoginWithSocialNetworkWidgetProps> = props => {
  const {
    roleType
  } = props;
  return _jsxs(SidebarLoginWithSocialNetworkWidgetStyled, {
    children: [_jsx(SidebarLoginWithSocialNetworkWidgetTitleStyled, {
      children: "\u0438\u043B\u0438 \u0438\u0441\u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u044C:"
    }), _jsx(ExternalAuthWidget, {
      roleType: roleType
    })]
  });
};