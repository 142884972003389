import { useMemo } from 'react';
import { AppFCC, USER_ACHIEVEMENT } from '@types';
import { UserAchievements } from '@kit/user-achievements';
import { ChatIcon, CoachIcon, ConsultantIcon, DiplomaIcon, FreeChatIcon, SexologistIcon } from '@icons';
import { UserAchievementsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const UserAchievementsWidget: AppFCC<UserAchievementsWidgetProps> = props => {
  const {
    achievements,
    iconsOnly,
    ...restProps
  } = props;
  const achievementChips = useMemo(() => {
    return {
      [USER_ACHIEVEMENT.DIPLOMA]: {
        icon: _jsx(DiplomaIcon, {}),
        text: iconsOnly //
        ? undefined : 'Диплом психолога'
      },
      [USER_ACHIEVEMENT.SEXOLOGIST]: {
        icon: _jsx(SexologistIcon, {}),
        text: iconsOnly //
        ? undefined : 'Сексолог'
      },
      [USER_ACHIEVEMENT.COACH]: {
        icon: _jsx(CoachIcon, {}),
        text: iconsOnly //
        ? undefined : 'Коуч'
      },
      [USER_ACHIEVEMENT.CONSULTANT]: {
        icon: _jsx(ConsultantIcon, {}),
        text: iconsOnly //
        ? undefined : 'Консультант'
      },
      [USER_ACHIEVEMENT.FREE_CONSULTANT]: {
        icon: iconsOnly ? _jsx(FreeChatIcon, {
          width: "3.25em"
        }) : _jsx(ChatIcon, {
          className: "fill-current text-green-54-47-100"
        }),
        text: iconsOnly ? undefined :
        //
        _jsx("span", {
          className: "text-green-54-47-100",
          children: "\u0411\u0435\u0441\u043F\u043B\u0430\u0442\u043D\u044B\u0439 \u0447\u0430\u0442"
        })
      }
    };
  }, [iconsOnly]);
  const items = useMemo(() => {
    return (achievements || []).map(achievement => achievementChips[achievement]);
  }, [achievementChips, achievements]);
  return _jsx(UserAchievements //
  , {
    ...restProps,
    items: items
  });
};