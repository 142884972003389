import { ERROR_CODE } from '@config/errors';
import { findByCode } from '@helpers/data';
import { hasAccountError } from '@helpers/errors';
import { BaseNetError } from '@errors/base-net-error';
import { accountFetchExists } from '@actions/account/account-fetch-exists/account-fetch-exists';
import { accountFetchExtendedRegister } from '@actions/account/account-fetch-extended-register';
import { notificationsShowPushPrompt } from '@actions/notifications/notifications-show-push-prompt';
import { AccountFetchExtendedRegisterWithErrorHandling } from './types';
export const accountFetchExtendedRegisterWithErrorHandling:
//
AccountFetchExtendedRegisterWithErrorHandling = (payload //
) => {
  return async dispatch => {
    const {
      accountFetchExistsPayload,
      accountFetchExistsPayload: {
        userName,
        password,
        visitId
      },
      accountFetchExistsOnSuccess,
      accountFetchExtendedRegisterOnSuccess,
      storage,
      push
    } = payload;
    try {
      const data = await dispatch(accountFetchExists({
        userName,
        password,
        visitId
      }));
      await accountFetchExistsOnSuccess(data);
      return;
    } catch (error) {
      if (error instanceof BaseNetError) {
        const errors = error.getOtherErrors();
        if (findByCode(errors, ERROR_CODE.USER_NOT_FOUND)) {
          try {
            const data = await dispatch(accountFetchExtendedRegister(accountFetchExistsPayload));
            await accountFetchExtendedRegisterOnSuccess(data);
            dispatch(notificationsShowPushPrompt({
              storage,
              push
            }));
            return;
          } catch (error) {
            if (error instanceof BaseNetError) {
              const errors = error.getOtherErrors();
              if (hasAccountError(errors)) {
                return;
              }
              throw error;
            }
            throw error;
          }
        }
        if (hasAccountError(errors)) {
          return;
        }
        throw error;
      }
      throw error;
    }
  };
};