import { ENDPOINTS } from '@config/endpoints';
import { jsonFetch, JsonFetchResult } from '@services/base/json-fetch';
import { AccountExistsRequestData, AccountExistsRequestDto, AccountExistsResponseData, AccountExistsResponseDto } from '@services/dto/account/account-exists';
import { accountExistsRequestToDto, accountExistsResponseFromDto } from '@mapping/account/account-exists';
type AccountExists = (requestData: AccountExistsRequestData) => JsonFetchResult<AccountExistsResponseData>;
export const accountExists: AccountExists = requestData => {
  const {
    promise,
    cancel
  } = jsonFetch<AccountExistsResponseDto, AccountExistsRequestDto>(ENDPOINTS.account.exists(), {
    method: 'POST',
    data: accountExistsRequestToDto(requestData)
  });
  return {
    promise: promise.then(accountExistsResponseFromDto),
    cancel
  };
};