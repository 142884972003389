import { AppFCC } from '@types';
import { BadgeStyled } from './styled';
import { BadgeProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const Badge: AppFCC<BadgeProps> = props => {
  const {
    children,
    className,
    color
  } = props;
  return _jsx(BadgeStyled //
  , {
    className: className,
    color: color,
    children: children
  });
};