import { AppFC } from '@types';
import { SidebarAccountLevelWidget } from '@widgets/sidebar/sidebar-account/sidebar-account-level';
import { SidebarAccountWalletWidget } from '@widgets/sidebar/sidebar-account/sidebar-account-wallet';
import { SidebarAccountWidgetStyled } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const SidebarAccountWidget: AppFC = () => {
  return _jsxs(SidebarAccountWidgetStyled, {
    children: [_jsx(SidebarAccountLevelWidget, {}), _jsx(SidebarAccountWalletWidget, {})]
  });
};