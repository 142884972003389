import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { isClient } from '@helpers/member';
import { isEmail } from '@helpers/misc';
import { accountFetchGetEmailIsConfirmed } from '@actions/account/account-fetch-get-email-is-confirmed';
import { accountGetEmailIsConfirmed } from '@selectors/account';
import { accountGetAccountEmail, accountGetIsAuthorized } from '@selectors/account';
import { currentMemberGetRoleType } from '@selectors/current-member';
import { usePopup } from '@hooks/popup';
import { useNavigator } from '@hooks/routing';
import { useTypedDispatch, useTypedSelector } from '@hooks/store';
import { AccountEmailConfirmationRequiredModalProps } from '@popups/modals/account/account-email-confirmation-required';
import { UseAccessControlCallbackByEmailConfirmation } from './types';
export const useAccessControlCallbackByEmailConfirmation: UseAccessControlCallbackByEmailConfirmation = routeDescriptor => {
  const dispatch = useTypedDispatch();
  const navigator = useNavigator();
  const {
    addPopup
  } = usePopup();
  const isAuthorized = useTypedSelector(accountGetIsAuthorized);
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const email = useTypedSelector(accountGetAccountEmail);
  const emailIsConfirmed = useTypedSelector(accountGetEmailIsConfirmed);
  const hasAccess = !isAuthorized ||
  // Not authorized
  roleType != null && isClient(roleType) ||
  // Data loaded and role type is client
  emailIsConfirmed != null && emailIsConfirmed ||
  // Data loaded and email is confirmed
  email != null && !isEmail(email); // User signed up with external auth

  const accessControlCallback = useCallback(async () => {
    if (emailIsConfirmed != null &&
    // Data loaded
    !emailIsConfirmed // Email is not confirmed
    ) {
      const data = await dispatch(accountFetchGetEmailIsConfirmed());
      if (data) {
        navigator.navigate(routeDescriptor);
        return;
      }
    }
    addPopup<AccountEmailConfirmationRequiredModalProps>({
      id: POPUP.ACCOUNT_EMAIL_CONFIRMATION_REQUIRED
    });
  }, [addPopup, dispatch, emailIsConfirmed, navigator, routeDescriptor]);
  return hasAccess //
  ? undefined : accessControlCallback;
};