import { useMemo } from 'react';
import { NEW_USER_RATING } from '@config/account';
import { ensureRatingIsNew } from '@helpers/member';
import { AppFC, AppFCC } from '@types';
import { currentMemberGetRating } from '@selectors/current-member';
import { useTypedSelector } from '@hooks/store';
import { Progressbar } from '@kit/progressbar';
import { SidebarRatingInfoPercentageNewValueStyled, SidebarRatingInfoPercentageStyled, SidebarRatingInfoPercentageSymbolStyled, SidebarRatingInfoPercentageValueStyled, SidebarRatingInfoStyled, SidebarRatingInfoTitleStyled, SidebarRatingProgressbarStyled, SidebarRatingStyled } from './styled';
import { SidebarRatingWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const _SidebarRatingWidget: AppFCC<SidebarRatingWidgetProps> = props => {
  const {
    rating
  } = props;
  const isNew = useMemo(() => ensureRatingIsNew(rating),
  //
  [rating]);
  const progressbarValue = isNew //
  ? NEW_USER_RATING : rating;
  return _jsxs(SidebarRatingStyled, {
    children: [_jsxs(SidebarRatingInfoStyled, {
      children: [_jsx(SidebarRatingInfoTitleStyled, {
        children: "\u0420\u0435\u0439\u0442\u0438\u043D\u0433 \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u0430"
      }), _jsx(SidebarRatingInfoPercentageStyled, {
        children: isNew ? _jsx(SidebarRatingInfoPercentageNewValueStyled, {
          children: "new"
        }) : _jsxs(_Fragment, {
          children: [_jsx(SidebarRatingInfoPercentageValueStyled, {
            children: rating
          }), _jsx(SidebarRatingInfoPercentageSymbolStyled, {
            children: "%"
          })]
        })
      })]
    }), _jsx(SidebarRatingProgressbarStyled, {
      children: _jsx(Progressbar, {
        value: progressbarValue
      })
    })]
  });
};
export const SidebarRatingWidget: AppFC = () => {
  const rating = useTypedSelector(currentMemberGetRating);
  return _jsx(_SidebarRatingWidget, {
    rating: rating
  });
};