import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE } from '@config/auth';
import { FRONTEND_APPLICATION_HOST } from '@config/env';
import { AccountExtendedRegisterRequestData, AccountExtendedRegisterRequestDto, AccountExtendedRegisterResponseData, AccountExtendedRegisterResponseDto } from '@services/dto/account/account-extended-register';
import { authTokenResponseFromDto } from '@mapping/auth/auth-token';
export const accountExtendedRegisterRequestToDto = (data: AccountExtendedRegisterRequestData): AccountExtendedRegisterRequestDto => {
  return {
    email: data.userName,
    password: data.password,
    passwordRepeated: data.passwordRepeated,
    displayName: data.displayName,
    birthDate: data.birthDate,
    roleType: data.roleType,
    sex: data.sex,
    visitId: data.visitId,
    url: data.url,
    clientType: data.clientType,
    userAgent: data.userAgent,
    timezoneId: data.timezoneId,
    timezoneOffset: data.timezoneOffset,
    culture: data.culture,
    frontEndApplicationHost: FRONTEND_APPLICATION_HOST,
    grantType: AUTH_GRANT_TYPE.PASSWORD,
    clientId: AUTH_CLIENT_ID
  };
};
export const accountExtendedRegisterResponseFromDto = (dto: AccountExtendedRegisterResponseDto): AccountExtendedRegisterResponseData => {
  const {
    data
  } = dto;
  return {
    jwt: authTokenResponseFromDto(data.jwt),
    userName: data.email,
    displayName: data.displayName,
    birthDate: data.birthDate,
    sex: data.sex,
    roleType: data.roleType,
    timezoneId: data.timezoneId,
    timezoneOffset: data.timezoneOffset,
    culture: data.culture
  };
};