import { useCallback, useMemo } from 'react';
import { POPUP } from '@config/popups';
import { formatCoins } from '@helpers/format';
import { isClient, isSpecialist } from '@helpers/member';
import { AppFC, AppFCC, CURRENCY_SYM } from '@types';
import { currentClientGetCoinAmount } from '@selectors/current-client';
import { currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { currentSpecialistGetBalanceAmount, currentSpecialistGetHasWallets } from '@selectors/current-specialist';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { PaymentsAddCardsAlertProps } from '@popups/modals/payments/payments-add-cards-alert';
import { PaymentsAddFundsModalProps } from '@popups/modals/payments/payments-add-funds';
import { PaymentsWithdrawFundsModalProps } from '@popups/modals/payments/payments-withdraw-funds';
import { BUTTON_COLOR, BUTTON_SIZE } from '@kit/buttons/button';
import { WalletIcon } from '@icons';
import { SidebarAccountWalletButtonStyled, SidebarAccountWalletIconStyled, SidebarAccountWalletInfoStyled, SidebarAccountWalletStyled, SidebarAccountWalletTextStyled } from './styled';
import { _SidebarAccountWalletWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const _SidebarAccountWalletWidget: AppFCC<_SidebarAccountWalletWidgetProps> = props => {
  const {
    roleType,
    profileIsFilledAndApproved,
    amount,
    onWithdrawFundsClick,
    onAddFundsClick
  } = props;
  const _isClient = isClient(roleType);
  const _isSpecialist = isSpecialist(roleType);
  const currency = useMemo(() => {
    return _isClient ? formatCoins(amount) : _isSpecialist ? CURRENCY_SYM.RUB : null;
  }, [_isClient, _isSpecialist, amount]);
  return _jsxs(SidebarAccountWalletStyled, {
    children: [_jsxs(SidebarAccountWalletInfoStyled, {
      children: [_jsx(SidebarAccountWalletIconStyled, {
        children: _jsx(WalletIcon, {})
      }), _jsxs(SidebarAccountWalletTextStyled, {
        children: [amount, " ", currency]
      })]
    }), !_isClient ? null : _jsx(SidebarAccountWalletButtonStyled, {
      size: BUTTON_SIZE.SMALL,
      color: BUTTON_COLOR.GREEN,
      disabled: !profileIsFilledAndApproved,
      onClick: onAddFundsClick,
      children: "\u041F\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u044C"
    }), !_isSpecialist ? null : _jsx(SidebarAccountWalletButtonStyled, {
      size: BUTTON_SIZE.SMALL,
      color: BUTTON_COLOR.GREEN,
      disabled: !profileIsFilledAndApproved || !amount,
      onClick: onWithdrawFundsClick,
      children: "\u0412\u044B\u0432\u0435\u0441\u0442\u0438"
    })]
  });
};
export const SidebarAccountWalletWidget: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const profileIsFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const coinAmount = useTypedSelector(currentClientGetCoinAmount);
  const specialistBalanceAmount = useTypedSelector(currentSpecialistGetBalanceAmount);
  const hasWallets = useTypedSelector(currentSpecialistGetHasWallets);
  const _isSpecialist = isSpecialist(roleType);
  const amount = _isSpecialist //
  ? specialistBalanceAmount : coinAmount;
  const openPaymentsAddFundsModal = useCallback(() => {
    addPopup<PaymentsAddFundsModalProps>({
      id: POPUP.PAYMENTS_ADD_FUNDS
    });
  }, [addPopup]);
  const mayBeOpenPaymentsWithdrawFundsModal = useCallback(() => {
    if (!hasWallets) {
      addPopup<PaymentsAddCardsAlertProps>({
        id: POPUP.PAYMENTS_ADD_CARDS
      });
      return;
    }
    addPopup<PaymentsWithdrawFundsModalProps>({
      id: POPUP.PAYMENTS_WITHDRAW_FUNDS
    });
  }, [addPopup, hasWallets]);
  return _jsx(_SidebarAccountWalletWidget //
  , {
    roleType: roleType,
    profileIsFilledAndApproved: profileIsFilledAndApproved,
    amount: amount,
    onAddFundsClick: openPaymentsAddFundsModal,
    onWithdrawFundsClick: mayBeOpenPaymentsWithdrawFundsModal
  });
};