import { useMemo } from 'react';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarProfileEditWidgetIconStyled } from './styled';
import { SidebarNavbarProfileEditWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarProfileEditWidget: AppFCC<SidebarNavbarProfileEditWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const profileEditRouteDescriptor = useMemo(() => ROUTES.profile.edit.index.getDescriptor(), []);
  const accessControlCallbacks = useMemo(() => [], []);
  return _jsx(NavbarItem, {
    text: "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0430\u043D\u043A\u0435\u0442\u0443",
    icon: _jsx(SidebarNavbarProfileEditWidgetIconStyled, {}),
    routeDescriptor: profileEditRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};