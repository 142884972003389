import { AppFC } from '@types';
import { currentSpecialistGetAchievements } from '@selectors/current-specialist';
import { useTypedSelector } from '@hooks/store';
import { UserAchievementsWidget } from '@widgets/user-achievements';
import { sidebarUserAchievementsWidgetItemClassName, sidebarUserAchievementsWidgetItemIconClassName, sidebarUserAchievementsWidgetItemTextClassName } from './styled';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarUserAchievementsWidget: AppFC = () => {
  const achievements = useTypedSelector(currentSpecialistGetAchievements);
  return _jsx(UserAchievementsWidget, {
    itemClassName: sidebarUserAchievementsWidgetItemClassName,
    itemIconClassName: sidebarUserAchievementsWidgetItemIconClassName,
    itemTextClassName: sidebarUserAchievementsWidgetItemTextClassName,
    achievements: achievements
  });
};