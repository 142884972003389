import { useCallback } from 'react';
import { POPUP } from '@config/popups';
import { isClient, isPremium } from '@helpers/member';
import { AppFC, AppFCC } from '@types';
import { currentClientGetLevel } from '@selectors/current-client';
import { currentMemberGetProfileIsFilledAndApproved, currentMemberGetRoleType } from '@selectors/current-member';
import { usePopup } from '@hooks/popup';
import { useTypedSelector } from '@hooks/store';
import { PaymentsUpgradeAccountModalProps } from '@popups/modals/payments/payments-upgrade-account';
import { BUTTON_COLOR, BUTTON_SIZE } from '@kit/buttons/button';
import { SidebarAccountLevelButtonStyled, SidebarAccountLevelInfoStyled, SidebarAccountLevelStyled, SidebarAccountLevelTextBasicStyled, SidebarAccountLevelTextPremiumStyled, SidebarAccountLevelTitleStyled } from './styled';
import { _SidebarAccountLevelWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const _SidebarAccountLevelWidget: AppFCC<_SidebarAccountLevelWidgetProps> = props => {
  const {
    profileIsFilledAndApproved,
    level,
    onUpgradeAccountClick
  } = props;
  return _jsxs(SidebarAccountLevelStyled, {
    children: [_jsxs(SidebarAccountLevelInfoStyled, {
      children: [_jsx(SidebarAccountLevelTitleStyled, {
        children: "\u0423\u0440\u043E\u0432\u0435\u043D\u044C:"
      }), isPremium(level) ? _jsx(SidebarAccountLevelTextPremiumStyled, {
        children: "Premium"
      }) : _jsx(SidebarAccountLevelTextBasicStyled, {
        children: "\u0411\u0430\u0437\u043E\u0432\u044B\u0439"
      })]
    }), isPremium(level) ? null : _jsx(SidebarAccountLevelButtonStyled //
    , {
      size: BUTTON_SIZE.SMALL,
      color: BUTTON_COLOR.DARK_BLUE_BORDERS,
      disabled: !profileIsFilledAndApproved,
      onClick: onUpgradeAccountClick,
      children: "\u041F\u043E\u0432\u044B\u0441\u0438\u0442\u044C"
    })]
  });
};
export const SidebarAccountLevelWidget: AppFC = () => {
  const {
    addPopup
  } = usePopup();
  const roleType = useTypedSelector(currentMemberGetRoleType);
  const profileIsFilledAndApproved = useTypedSelector(currentMemberGetProfileIsFilledAndApproved);
  const level = useTypedSelector(currentClientGetLevel);
  const openUpgradeAccountModal = useCallback(() => {
    addPopup<PaymentsUpgradeAccountModalProps>({
      id: POPUP.PAYMENTS_UPGRADE_ACCOUNT,
      props: {}
    });
  }, [addPopup]);
  const _isClient = isClient(roleType);
  if (!_isClient) {
    return null;
  }
  return _jsx(_SidebarAccountLevelWidget, {
    level: level,
    profileIsFilledAndApproved: profileIsFilledAndApproved,
    onUpgradeAccountClick: openUpgradeAccountModal
  });
};