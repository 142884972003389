import { AppFCC, ROLE_TYPE } from '@types';
import { Tabs, TabsTab } from '@kit/tabs';
import { RolePickerWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { jsxs as _jsxs } from "@emotion/react/jsx-runtime";
export const RolePickerWidget: AppFCC<RolePickerWidgetProps> = props => {
  const {
    className,
    buttonClassName,
    disabled,
    roleType,
    onChange
  } = props;
  return _jsxs(Tabs, {
    className: className,
    children: [_jsx(TabsTab, {
      className: buttonClassName,
      value: ROLE_TYPE.CLIENT,
      isActive: roleType === ROLE_TYPE.CLIENT,
      disabled: disabled,
      onClick: onChange,
      children: "\u042F - \u043A\u043B\u0438\u0435\u043D\u0442"
    }), _jsx(TabsTab, {
      className: buttonClassName,
      value: ROLE_TYPE.SPECIALIST,
      isActive: roleType === ROLE_TYPE.SPECIALIST,
      disabled: disabled,
      onClick: onChange,
      children: "\u042F - \u043F\u0441\u0438\u0445\u043E\u043B\u043E\u0433"
    })]
  });
};