import { AUTH_CLIENT_ID, AUTH_GRANT_TYPE } from '@config/auth';
import { AccountExistsRequestData, AccountExistsRequestDto, AccountExistsResponseData, AccountExistsResponseDto } from '@services/dto/account/account-exists';
import { authTokenResponseFromDto } from '@mapping/auth/auth-token';
export const accountExistsRequestToDto = (data: AccountExistsRequestData): AccountExistsRequestDto => {
  return {
    userName: data.userName,
    password: data.password,
    visitId: data.visitId,
    grantType: AUTH_GRANT_TYPE.PASSWORD,
    clientId: AUTH_CLIENT_ID
  };
};
export const accountExistsResponseFromDto = (dto: AccountExistsResponseDto): AccountExistsResponseData => {
  return authTokenResponseFromDto(dto.data);
};