import { useMemo } from 'react';
import { formatCounter } from '@helpers/format';
import { ROUTES } from '@routing/routes';
import { AppFCC } from '@types';
import { invitationsGetPendingInvitationsCount } from '@selectors/invitations';
import { useAccessControlCallbackByEmailConfirmation } from '@hooks/access-control/access-control-by-email-confirmation';
import { useAccessControlCallbackByFilledProfile } from '@hooks/access-control/access-control-by-filled-profile';
import { useTypedSelector } from '@hooks/store';
import { NavbarItem } from '@kit/navbar/navbar-item';
import { SidebarNavbarInvitationsWidgetIconStyled } from './styled';
import { SidebarNavbarInvitationsWidgetProps } from './types';
import { jsx as _jsx } from "@emotion/react/jsx-runtime";
export const SidebarNavbarInvitationsWidget: AppFCC<SidebarNavbarInvitationsWidgetProps> = props => {
  const {
    shadowPosition,
    onClick
  } = props;
  const pendingInvitationsCount = useTypedSelector(invitationsGetPendingInvitationsCount);
  const invitationsRouteDescriptor = useMemo(() => ROUTES.invitations.index.getDescriptor(), []);
  const accessControlCallbackByEmailConfirmation = useAccessControlCallbackByEmailConfirmation(invitationsRouteDescriptor);
  const accessControlCallbackByFilledProfile = useAccessControlCallbackByFilledProfile(invitationsRouteDescriptor);
  const accessControlCallbacks = useMemo(() => [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile], [accessControlCallbackByEmailConfirmation, accessControlCallbackByFilledProfile]);
  return _jsx(NavbarItem, {
    text: "\u041C\u043E\u0438 \u043F\u0440\u0438\u0433\u043B\u0430\u0448\u0435\u043D\u0438\u044F",
    icon: _jsx(SidebarNavbarInvitationsWidgetIconStyled, {}),
    badgeText: formatCounter(pendingInvitationsCount),
    routeDescriptor: invitationsRouteDescriptor,
    accessControlCallbacks: accessControlCallbacks,
    checkActive: true,
    shadowPosition: shadowPosition,
    onClick: onClick
  });
};